<template>
    <section class="modal-glass" @click="outsideClick">
        <div class="modal">
            <div class="close" @click="close"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="Component 1" clip-path="url(#clip0_1363_566)">
                        <path id="Vector"
                            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1363_566">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg></div>

            <template v-if="sendSuccess || sendFail">
                <h2 class="title_send_request">{{ sendTitle }}</h2>
                <p class="text_send_request">{{ sendMessage }}</p>
                <button class="send_response_button send_button" @click="close">Закрыть</button>
            </template>
            <template v-else>
                <template v-if="typeModal === 'cost'">
                    <h2 class="title_send_request">Запросить стоимость</h2>
                    <p class="text_send_request"> Вы можете отправить нам заявку на расчет стоимости и срока поставки
                        лицензии
                        (коммерческого предложения)
                    </p>
                </template>
                <template v-else-if="typeModal === 'demo'">
                    <h2 class="title_send_request">Отправить запрос</h2>
                    <p class="text_send_request"> Вы можете отправить нам заявку на расчет стоимости и срока поставки
                        лицензии
                        (коммерческого предложения)
                    </p>
                </template>
                <template v-else>
                    <h2 class="title_send_request">Отправить сообщение</h2>
                </template>
                <form @submit.prevent="handleSubmit" class="request_form">
                    <label class="input_block" v-if="typeModal === 'demo'">
                        <span>Тип запроса</span>
                        <select v-model="form.request_type" class="input" name="request_type">
                            <option v-for="option in options" :key="option.value" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                    </label>
                    <label class="input_block" v-if="typeModal === 'cost'">
                        <span>Тип продукта</span>
                        <select v-model="form.product" class="input" name="product" v-if="solutions">
                            <option v-for="solution in solutions_list" :key="solution.value" :value="solution.value">
                                {{ solution.text }}
                            </option>
                        </select>
                        <select v-model="form.product" class="input" name="product" v-else>
                            <option v-for="product in products" :key="product.id" :value="product.id">
                                {{ product.details.header.title }}
                            </option>
                        </select>
                    </label>

                    <label class="input_block">
                        <span>Имя</span>
                        <input v-model="form.firstName" placeholder="Введите ваше имя" name="firstName" type="text"
                            class="input" required>
                    </label>
                    <label class="input_block">
                        <span>Телефон</span>
                        <input v-model="form.phone" placeholder="Введите номер телефона" class="input" name="phone"
                            type="tel" required>
                    </label>
                    <label class="input_block">
                        <span>Комментарий</span>
                        <textarea v-model="form.comment" placeholder="Текст сообщения" name="comment" class="message_input"
                            required></textarea>
                    </label>
                    <div class="checkbox_block">
                        <input type="checkbox" id="checkbox" v-model="form.checked" required />
                        <label for="checkbox" required class="checkbox_box">Нажимая кнопку «Отправить», я даю свое согласие
                            на
                            обработку персональных
                            данных
                            и
                            соглашаюсь с политикой конфиденциальности компаниии ООО«Авида-смарт» </label>
                    </div>
                    <button class="send_button" type="submit"> Отправить</button>
                </form>
            </template>
        </div>
    </section>
</template>
<script setup>


import { ref, defineEmits, onMounted } from "vue";
import { getProducts } from '@/service/fetch.service.js';


const sendSuccess = ref(false)
const sendFail = ref(false)
const sendTitle = ref();
const sendMessage = ref();
const solutions = ref(false);
const products = ref()
onMounted(async() => {
    products.value = await getProducts();
})


solutions.value = isSolution();
function isSolution() {
    if (window.location.pathname == '/biometric.html' || window.location.pathname == '/smartocr.html' || window.location.pathname == '/dextractor.html') {
        return true
    } else {
        return false
    }
}

const form = ref({
    request_type: '',
    product: '',
    firstName: '',
    phone: '',
    comment: '',
    checked: ''
})



const options = ref([
    { text: 'Запрос тестового доступа', value: 'test' },
    { text: 'Заявка на расчет стоимости', value: 'cost' },
    { text: 'Заявка на расчет срока поставки лицензии', value: 'license' }
]);
const solutions_list = ref([
    { text: 'СИСТЕМА БИОМЕТРИЧЕСКОЙ ИДЕНТИФИКАЦИИ', value: 'biometric' },
    { text: 'СЕРВИС РАСПОЗНАВАНИЯ И КОНВЕРТАЦИИ ДОКУМЕНТОВ', value: 'smartocr' },
    { text: 'СИСТЕМА ОБРАБОТКИ И РАСПРЕДЕЛЕНИЯ СООБЩЕНИЙ ПОЛЬЗОВАТЕЛЕЙ', value: 'dextractor' }
]);

const emit = defineEmits(['closeModal', 'showSuccessMsg'])
function close() {
    emit('closeModal', false);
}
function outsideClick(e) {
    if (e.target.classList.contains('modal-glass')) {
        emit('closeModal', false);
    }
}
const props = defineProps({
    typeModal: {
        type: String,
        default: 'message'
    }
})

async function handleSubmit() {

    let response = await fetch('../send.php', {
        method: 'POST',
        body: { ...form.value },
    })

    if (response.ok) {
        sendTitle.value = 'Ваше сообщение успешно отправлено!';
        sendMessage.value = 'Спасибо за обращение! Наш менеджер свяжеться с Вами в ближайшее время.';
        sendSuccess.value = true;
    } else {
        sendTitle.value = 'Ошибка!';
        sendMessage.value = 'Произошла ошибка при отправке сообщения. Попробуйте позже.';
        sendFail.value = true;
    }
}
</script>

<style lang="scss" scoped>
.modal-glass {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999990;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
}

.modal {
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 999999;
    overflow: auto;
    display: flex;
    width: rem(455);
    justify-content: center;
    background: linear-gradient(18.43deg, #010326 -6.05%, #0D1BF5 102.13%);
    padding: rem(68) rem(48);
    flex-direction: column;
    align-items: center;
}


.close {
    width: rem(24);
    height: rem(24);
    display: block;
    position: absolute;
    top: rem(24);
    right: rem(24);
    cursor: pointer;

    .svg {
        pointer-events: none;
        display: block;
        height: 100%;
        width: auto;
    }
}

.content {
    width: rem(360);
}

.open {
    overflow: hidden;
}

.request_form {
    display: flex;
    flex-direction: column;
}

.title_send_request {
    color: white;
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
    margin-bottom: rem(40);
    text-align: center;
    min-width: rem(352);
}


.text_send_request,
.request_form {
    color: white;
    font-size: 16px;
    font-family: PT Sans;
    font-weight: 400;
    word-wrap: break-word;


}

.text_send_request {
    margin-bottom: rem(40);
    text-align: center;
    text-align-last: center;
    max-width: rem(370);
}

.input_block {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(20);
}

.send_button {
    font-family: Comfortaa;
    cursor: pointer;
    text-transform: uppercase;
    font-size: rem(16);
    line-height: rem(18);
    border: 2px solid #fff;
    color: #fff;
    width: 100%;

    @include mobile {
        padding: rem(16) rem(24);
    }

    @include desktop {
        padding: rem(16) rem(24);
    }

    &:hover {
        background: #6FAEE9;
    }

    &:active {
        background: #0D1BF5;
    }

}

#checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &+label {
        display: inline-flex;
        align-items: top;
        user-select: none;
        font-size: 12px;
        font-family: PT Sans;
        font-weight: 400;

    }

    &+label::before {
        content: '';
        display: inline-block;
        width: rem(18);
        height: rem(18);
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #fff;
        margin-right: 0.5em;
        margin-top: 0.25em;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
        margin-right: rem(14);
    }

    &:checked+label::before {
        border-color: #0b76ef;
        background-color: #0b76ef;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    /* стили при наведении курсора на checkbox */
    &:not(:disabled):not(:checked)+label:hover::before {
        border-color: #fff
    }

    /* стили для активного состояния чекбокса (при нажатии на него) */
    &:not(:disabled):active+label::before {
        background-color: #b3d7ff;
        border-color: #fff;
    }


    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    &:focus:not(:checked)+label::before {
        border-color: #80bdff;
    }

    /* стили для чекбокса, находящегося в состоянии disabled */
    &:disabled+label::before {
        background-color: #e9ecef;
    }
}

.checkbox_block {
    display: flex;
    flex-direction: row;
    margin-bottom: rem(40);
}

.input {
    height: rem(34);
    color: #215378;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 20px;
    padding: rem(8);
    outline: none;

}

.message_input {
    height: rem(83);
    color: #215378;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 20px;
    padding: rem(8);
    outline: none;

}

.request_form {
    width: 100%
}
</style>
